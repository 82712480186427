import { Type } from '@yoolabs/class-transformer'
import { TransactionRecord } from "./TransactionRecord"

export class TransactionRecordConnection {
  id!: number
  created_at!: string
  entry_id!: number

  @Type(() => TransactionRecord)
  transaction_record!: TransactionRecord
}
