import { Type } from '@yoolabs/class-transformer'
import { EntryHead } from "./Entry"

export class EntryConnection {
  id!: number
  entry_id!: number
  created_at!: string

  @Type(() => EntryHead)
  entry_head!: EntryHead
}
