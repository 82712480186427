import { type App } from "vue"
import { Tooltip } from 'bootstrap'

export default function(app: App) {
  app.directive('bs-tooltip', function(el, binding) {
    new Tooltip(el, {
      title: binding.value,
      placement: binding.arg as any,
      trigger: 'hover'
    })
  })


  app.directive('bs-confirm', {
    created(el, binding) {
      el.confirmHandler = function(event: Event) {
        const message = binding.value ?? "确认操作?"
        const answer = confirm(message)

        if (answer === false) {
          event.preventDefault()
          event.stopImmediatePropagation()
        }
      }

      el.addEventListener('click', el.confirmHandler)
    },

    unmounted(el, binding) {
      el.removeEventListener('click', el.confirmHandler)
    },
  })
}
