import { Type } from '@yoolabs/class-transformer'

export class CategoryHead {
  id!: number
  name!: string
  icon!: string
}

export class Category extends CategoryHead {
  @Type(() => CategoryHead)
  parent?: CategoryHead
  parent_id?: number
  direction!: string
}
