import { type App } from "vue";

export default function install(app: App) {
  const router = app.config.globalProperties.$router

  // router.onError((err) => {
  //   router.replace("/error")
  // })

  // app.config.errorHandler = function(err, instance, info) {
  //   // router.replace({ name: "error")
  //   console.log("errorHandler", err)
  // }
}
