import { Type } from '@yoolabs/class-transformer'
import { Category } from "./Category"
import { EntryConnection } from "./EntryConnection"
import { Project } from './Project'

export class TransactionRecordHead {
  id!: number
  state!: string
  payment_at!: string
  subject!: string
  description!: string
  content!: string
  direction!: string
  amount!: string
  currency!: string
  origin_amount!: string
  origin_currency!: string
  account_id!: number
  category_id!: number
  project_id!: number

  @Type(() => Project) project!: Project
  @Type(() => Category) category!: Category
}

export class TransactionRecord extends TransactionRecordHead {
  @Type(() => EntryConnection)
  entry_connections!: EntryConnection[]
  row_id!: string
}
