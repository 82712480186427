import type { Frontline } from "@/lib/frontline"
import { plainToInstance } from "@yoolabs/class-transformer"
import type { AxiosRequestConfig, AxiosResponse } from "axios"
import _ from "lodash"
import qs from "qs"
import { ReplaySubject, Subject, lastValueFrom } from "rxjs"
import URI from 'urijs'
import { parseTemplate } from 'url-template'

export default abstract class <T> {
  frontline!: Frontline

  unique = false
  endpoint = null! as string
  interpolations = {} as Record<string, any>
  query = {} as Record<string, any>
  method = "GET" as "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | string
  headers = {}
  graph = null as 'info' | 'head' | string | null

  subject!: Subject<T>
  controller = new AbortController()

  async fire(callback?: ((req: this) => void) | null, params = {}): Promise<T> {
    try {
      const subject = this.fireOnce(callback, params)
      const result = await lastValueFrom(subject)
      return result
    } catch (e) {
      return await this.processError(e)
    }
  }

  abort() {
    this.controller.abort()
  }

  validateStatus(status: number) {
    return 200 <= status && status < 300
  }

  fireOnce(callback?: ((req: this) => void) | null, params = {}) {
    if (this.subject != null) {
      return this.subject
    } else {
      this.subject = new ReplaySubject()
    }

    callback?.(this)
    const config = this.buildPerformConfig(params)

    this.request(config).then(resp => {
      const result = this.processResponse(resp)
      this.subject.next(result)
      this.subject.complete()
    }).catch(e => {
      this.subject.error(e)
    })

    return this.subject
  }

  async request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    if (config.method == "GET") {
      const key = config.url!

      const cache_value = await this.$keyv.get(key)
      if (cache_value) {
        return cache_value
      }

      const resp = await this.$axios.request(config)
      await this.$keyv.set(key, resp)
      return resp
    } else {
      const resp = await this.$axios.request(config)
      await this.$keyv.clear()
      return resp
    }
  }

  buildPerformConfig(params) {
    const form_data = this.buildFormData(params)
    const url = this.buildUrl()

    const config = <AxiosRequestConfig>{
      url: url,
      method: this.method,
      data: form_data,
      headers: this.headers,
      validateStatus: this.validateStatus,
      signal: this.controller.signal,
    }

    if (this.graph) {
      config.headers!["X-Resource-Graph"] = this.graph
    }

    return config
  }

  buildUrl() {
    const url = parseTemplate(this.endpoint).expand(this.interpolations)
    const uri = new URI(url)
    const query_string = qs.stringify(this.query, { arrayFormat: "brackets" })
    return uri.query(query_string).toString()
  }

  async processError(e: any): Promise<T> {
    throw e
  }

  buildFormData(params) {
    const form_data = new FormData()
    for (const name in params) {
      const value = params[name]
      this.fillFormData(form_data, name, value)
    }
    return form_data
  }

  fillFormData(formData, name, value) {
    if (value instanceof File) {
      formData.append(name, value)
    } else if (_.isArray(value)) {
      for (const [index, val] of value.entries()) {
        this.fillFormData(formData, `${name}[]`, val);
      }
    } else if (_.isObjectLike(value)) {
      for (const attr in value) {
        const val = value[attr]
        this.fillFormData(formData, `${name}[${attr}]`, val);
      }
    } else {
      formData.append(name, value || "")
    }
  }

  abstract processResponse(response: AxiosResponse): T

  get $axios() { return this.frontline.weapons }
  get $keyv() { return this.frontline.supplies }

  responseToObject<T>(klass: new (...args: any[]) => T, response: AxiosResponse) {
    return plainToInstance<T, any>(klass, response.data)
  }

  responseToArray<T>(klass: new (...args: any[]) => T, response: AxiosResponse) {
    return plainToInstance<T, any[]>(klass, response.data)
  }
}
