import { Session } from "@/models"
import { type AxiosResponse } from "axios"
import BaseRequest from "./BaseRequest"

export default {
  Get: class extends BaseRequest<Session | null> {
    endpoint = "/session"
    method = "GET"

    validateStatus(status: number) {
      return super.validateStatus(status) || status == 404
    }

    processResponse(response: AxiosResponse): Session | null {
      if (response.status == 404) {
        return null
      }

      return this.responseToObject(Session, response)
    }
  },

  Create: class extends BaseRequest<Session> {
    endpoint = "/session"
    method = "POST"

    processResponse(response: AxiosResponse) {
      return this.responseToObject(Session, response)
    }
  },

  Destroy: class extends BaseRequest<null> {
    endpoint = "/session"
    method = "DELETE"

    processResponse(response: AxiosResponse) {
      return null
    }
  }
}
