export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/index.vue'),
    /* no children */
  },
  {
    path: '/accounts',
    /* internal name: '/accounts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/accounts/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/accounts/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/accounts/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/accounts/[id].vue'),
        /* no children */
      },
      {
        path: ':id/edit',
        name: '/accounts/[id].edit',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/accounts/[id].edit.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/accounts/new',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/accounts/new.vue'),
        /* no children */
      },
      {
        path: 'summary',
        name: '/accounts/summary',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/accounts/summary.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/categories',
    /* internal name: '/categories' */
    /* no component */
    children: [
      {
        path: '',
        name: '/categories/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/categories/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/error',
    name: '/error',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/error.vue'),
    /* no children */
  },
  {
    path: '/inbox',
    /* internal name: '/inbox' */
    /* no component */
    children: [
      {
        path: '',
        name: '/inbox/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/inbox/index.vue'),
        /* no children */
      },
      {
        path: ':channel',
        name: '/inbox/[channel]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/inbox/[channel].vue'),
        /* no children */
      },
      {
        path: 'mailbox',
        name: '/inbox/mailbox',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/inbox/mailbox.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/projects/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/refresh',
    name: '/refresh',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/refresh.vue'),
    /* no children */
  },
  {
    path: '/sign_in',
    name: '/sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/test',
    name: '/test',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/duizd/app/javascript/frontend/pages/test.vue'),
    /* no children */
  }
]
