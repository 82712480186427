import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto/routes'
import { setupLayouts } from 'virtual:meta-layouts'
import { type App } from 'vue'

console.log(routes)

const routes_with_layout = setupLayouts(routes)
const router = createRouter({
  history: createWebHistory(),
  routes: routes_with_layout,
})

export default function(app: App) {
  app.config.globalProperties.$router = router as any;
  app.use(router);
}

// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $router: typeof router
//   }
// }
